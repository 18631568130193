<template>
  <div>
    <Dialog :commonDialog="dialog" :dialog-width="dialogWidth">
      <template v-slot:title>
        <v-layout class="px-4">
          <v-flex> Create Opportunity </v-flex>
          <v-flex class="text-right">
            <v-btn
              class="custom-bold-button mr-4"
              depressed
              :disabled="!formValid || formLoading"
              :loading="formLoading"
              v-on:click="onSubmit()"
              color="cyan white--text"
              >Save
            </v-btn>
            <v-btn
              class="custom-bold-button custom-border"
              depressed
              :disabled="formLoading"
              v-on:click="$emit('close', true)"
              >Cancel</v-btn
            >
          </v-flex>
        </v-layout>
      </template>

      <template v-slot:body>
        <v-container>
          <v-form
            ref="oppForm"
            v-model="formValid"
            lazy-validation
            v-on:submit.stop.prevent="onSubmit"
          >
            <v-layout>
              <v-row>
                <v-col md="12">
                  <div>
                    <label class="font-weight-700 font-size-16 required"
                      >Opportunity Name
                    </label>
                    <!-- <v-text-field
                    v-model.trim="opportunityCreate.name"
                    dense
                    filled
                    color="cyan"
                    label="Opportunity Name"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    solo
                    flat
                  ></v-text-field> -->
                    <TextValidateInput
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      :rules="[
                        validateRules.required(
                          opportunityCreate.opportunity_name,
                          'Opportunity Name'
                        ),
                        validateRules.maxLength(
                          opportunityCreate.opportunity_name,
                          'Opportunity Name',
                          50
                        ),
                      ]"
                      id="display-name"
                      placeholder="Name"
                      counter="50"
                      :validationField="{
                        url_type: 'opportunity',
                        filter_type: 'opportunity',
                        field: 'opportunity_name',
                      }"
                      :parent-id="opportunityCreate.id"
                      :current-id="opportunityCreate.id"
                      show-dropdown
                      v-model="opportunityCreate.opportunity_name"
                      hideTopMargin
                    ></TextValidateInput>
                  </div>
                </v-col>
                <v-col md="6">
                  <div>
                    <label class="font-weight-700 font-size-16"
                      >Start Date
                    </label>
                    <DatePicker solo :placeholder="'Start Date *'"
                    :default-date=" opportunityCreate.started_at "
                    v-model="opportunityCreate.started_at" mandatory: true />
                  </div>
                </v-col>
                <v-col md="6">
                  <div>
                    <label class="font-weight-700 font-size-16"
                      >End Date
                    </label>
                    <DatePicker solo :placeholder="'End Date'" :default-date="
                    opportunityCreate.deadline "
                    :min-date="opportunityCreate.started_at"
                    v-model="opportunityCreate.deadline" mandatory: true />
                  </div>
                </v-col>
                <v-col md="12">
                  <div>
                    <label class="font-weight-700 font-size-16 required"
                      >Project
                    </label>
                    <v-autocomplete
                      :rules="[
                        validateRules.required(
                          opportunityCreate.project_id,
                          'Project'
                        ),
                        validateRules.maxLength(
                          opportunityCreate.project_id,
                          'Project',
                          50
                        ),
                      ]"
                      dense
                      color="cyan"
                      filled
                      id="project"
                      :items="projectList"
                      :disabled="formLoading"
                      :loading="formLoading"
                      v-model="opportunityCreate.project_id"
                      placeholder="Select Project"
                      solo
                      flat
                      item-color="cyan"
                      item-text="name"
                      item-value="id"
                      hide-details
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title></v-list-item-title>
                            <v-list-item-subtitle
                              v-html="'No Project Found.'"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <!-- <template v-slot:append-item>
                      <v-divider class="mt-2"></v-divider>
                      <v-btn
                        class="mx-3"
                        depressed
                        @click="addProjectDialog = true"
                        ><v-icon color="blue">mdi-plus</v-icon
                        ><span class="blue--text"> Add New</span>
                      </v-btn>
                      <template v-if="false">
                        <v-row class="mx-0">
                          <v-col md="12">
                            <div>
                              <label
                                class="font-weight-700 font-size-16 required"
                                >Project Title
                              </label>
                              <v-text-field
                                v-model.trim="opportunityCreate.name"
                                dense
                                filled
                                color="cyan"
                                label="Project Name"
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                solo
                                flat
                              ></v-text-field>
                            </div>
                          </v-col>
                          <v-col md="6">
                            <div>
                              <label class="font-weight-700 font-size-16"
                                >Start Date
                              </label>
                              <DatePicker solo :placeholder="'Start Date *'"
                              :default-date=" opportunityCreate.started_at "
                              v-model="opportunityCreate.started_at" mandatory:
                              true />
                            </div>
                          </v-col>
                          <v-col md="6">
                            <div>
                              <label class="font-weight-700 font-size-16"
                                >End Date
                              </label>
                              <DatePicker solo :placeholder="'End Date'"
                              :default-date=" opportunityCreate.deadline "
                              :min-date="opportunityCreate.started_at"
                              v-model="opportunityCreate.deadline" mandatory:
                              true />
                            </div>
                          </v-col>
                          <v-col md="12">
                            <v-btn
                              @click="addProjectDialog = false"
                              color="blue darken-4"
                              class="mx-3"
                              depressed
                              ><span class="white--text">Save</span>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </template>
                    </template> -->
                    </v-autocomplete>
                  </div>
                </v-col>
                <v-col
                  md="12"
                  class="border mx-2"
                  style="background-color: rgb(248 238 238)"
                  v-if="addProjectDialog == true"
                >
                  <v-row>
                    <v-col md="12">
                      <div>
                        <label class="font-weight-700 font-size-16 required"
                          >Project Title
                        </label>
                        <v-text-field
                          v-model.trim="opportunityCreate.project_name"
                          dense
                          filled
                          color="cyan"
                          label="Project Name"
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          solo
                          flat
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col md="6">
                      <div>
                        <label class="font-weight-700 font-size-16"
                          >Project Start Date
                        </label>
                        <DatePicker solo :placeholder="'Start Date *'"
                        :default-date=" opportunityCreate.project_started_at "
                        v-model="opportunityCreate.project_started_at"
                        mandatory: true />
                      </div>
                    </v-col>
                    <v-col md="6">
                      <div>
                        <label class="font-weight-700 font-size-16"
                          >Project End Date
                        </label>
                        <DatePicker solo :placeholder="'End Date'"
                        :default-date=" opportunityCreate.deadline "
                        :min-date="opportunityCreate.project_started_at"
                        v-model="opportunityCreate.deadline" mandatory: true />
                      </div>
                    </v-col>
                    <!-- <v-col md="12">
                    <v-btn
                      @click="addProjectDialog = false"
                      color="blue darken-4"
                      class="me-2"
                      depressed
                      ><span class="white--text">Save</span>
                    </v-btn>
                    <v-btn
                      depressed
                      @click="addProjectDialog = false"
                      color=""
                      class=""
                      ><span class="">Cancel</span>
                    </v-btn>
                  </v-col> -->
                  </v-row>
                </v-col>
              </v-row>
            </v-layout>
          </v-form>
        </v-container>
      </template>
    </Dialog>
    <!-- <AddProjectDialog
      :dialog="addProjectDialog"
      v-on:close="addProjectDialog = false"
    /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import Dialog from "@/view/pages/partials/Dialog.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
// import AddProjectDialog from "@/view/components/AddProjectDialog.vue";
import ObjectPath from "object-path";
import { QUERY } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { POST } from "@/core/services/store/request.module";
import TextValidateInput from "@/view/pages/partials/TextValidateInput.vue";

import moment from "moment-timezone";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  name: "opportunity",
  mixins: [CommonMixin, ValidationMixin],
  data() {
    return {
      pageLoading: false,
      formLoading: false,
      formValid: true,
      addProjectDialog: false,
      projectList: [],
      opportunityCreate: {
        name: null,
        project_id: null,
        project_title: null,
        started_at: moment().format("YYYY-MM-DD"),
        deadline: moment().add(1, "month").format("YYYY-MM-DD"),
      },
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    dialog(param) {
      if (param) {
        this.search = null;
      }
    },
  },
  methods: {
    onSubmit() {
      const _this = this;
      try {
        const formErrors = _this.validateForm(_this.$refs.oppForm);

        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }
        if (!this.opportunityCreate?.project_id) {
          ErrorEventBus.$emit("update:error", "Please Select Project.");
          return false;
        }
        _this.formLoading = true;

        let REQUEST_TYPE = POST;
        let REQUEST_URL = "opportunity";

        _this.$store
          .dispatch(REQUEST_TYPE, {
            url: REQUEST_URL,
            data: _this.opportunityCreate,
          })
          .then(() => {
            this.$emit("close", true);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },

    getprojects() {
      this.loading = true;
      this.list = [];
      this.$store
        .dispatch(QUERY, {
          url: "project-list",
          data: { search: this.search },
        })
        .then((response) => {
          this.projectList = ObjectPath.get(response, "data", []);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    Dialog,
    DatePicker,
    TextValidateInput,
    // AddProjectDialog,
  },
  mounted() {
    this.$refs.oppForm.validate();
    this.getprojects();
  },

  computed: {
    ...mapGetters(["preventiveScheduleOutput", "prventiveRawScheduleOutput"]),
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
