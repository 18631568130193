<template>
  <Dialog :commonDialog="dialog" :dialogWidth="760">
    <template v-slot:title>
      <v-layout>
        <v-flex> Manage Validity Type</v-flex>
        <v-flex class="text-right">
          <v-btn small class="mx-4" v-on:click="createContractType">
            <span class="font-size-16 font-weight-600">Add Validity Type</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 90vh; position: relative"
      >
        <v-container class="py-0">
          <v-row class="py-0">
            <v-col md="12" class="py-0">
              <table class="width-100">
                <tbody>
                  <tr v-for="(type, index) in validity" :key="index">
                    <td>
                      <v-text-field
                        v-model.trim="type.text"
                        dense
                        filled
                        label="Validate"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="50">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-on:click="deleteContractType(index)"
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="pageLoading"
        :loading="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="updateContractSetting"
      >
        Save
      </v-btn>
      <v-btn
        :disabled="pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close-dialog', true)"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>
  <script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import { PATCH } from "@/core/services/store/request.module";
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    e_department: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
    e_costing: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
  },
  watch: {
    e_costing: {
      deep: true,
      immediate: true,
      handler(param) {
        this.validity = param;
      },
    },
  },
  data: () => {
    return {
      pageLoading: false,
      module: "costing",
      validity: [
        {
          title: null,
          value: null,
        },
      ],
    };
  },
  methods: {
    updateContractSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: `setting/validity/${this.module}`,
          data: { validity_: _this.validity },
        })
        .then(() => {
          _this.$emit("close-dialog", true);
          _this.$emit("get-e-designation", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    createContractType() {
      this.validity.push({
        value: null,
      });
    },
    deleteContractType(index) {
      if (this.validity.length > 1) {
        this.validity.splice(index, 1);
      }
    },
  },
  components: {
    Dialog,
  },
};
</script>
