<template>
  <div class="line-item-v2">
    <div class="d-flex mb-2 align-center justify-space-between">
      <div class="d-flex">
        <v-btn-toggle color="cyan" group>
          <!--  <v-btn
            color="cyan"
            class="custom-bold-button"
            value="header"
            @click="addParent"
            style="height: 32px !important"
          >
            Header
          </v-btn> -->
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="other"
            v-on:click="add_component(0)"
            style="height: 32px !important"
          >
            Component
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <div class="custom-grey-border">
      <div class="scrollbar-wrapper">
        <div class="scrollbar" ref="topScrollbar" @scroll="syncScrollFromTop">
          <div class="scroll-content"></div>
        </div>
      </div>
      <!-- Bottom scrollbar -->
      <div
        class="content-wrapper"
        ref="scrollContainer"
        style="max-width: 2697px; overflow: scroll; display: grid"
        @scroll="syncScrollFromBottom"
      >
        <div class="d-flex grey lighten-4">
          <div
            class="color-custom-blue py-2 font-weight-500 text-center"
            style="min-width: 30px"
          ></div>
          <div
            class="color-custom-blue py-2 px-2 font-weight-500 text-left"
            style="min-width: 205px"
          >
            Brand
          </div>

          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 205px"
          >
            Model
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 205px"
          >
            Part
          </div>
          <div
            class="color-custom-blue py-2 px-2 font-weight-500 text-left"
            style="min-width: 116px"
          >
            Product Type
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            Currency
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            Cost
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            FLT
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            XR
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            GEAR
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            LAMP
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            ACC
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            UNIT COST
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            MARKUP %
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            UNIT SELLING
          </div>

          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            REQ. QTY
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            PRO. QTY
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            UOM
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            PRO. UNIT SELL.
          </div>

          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            TOTAL COST
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            TOTAL SELLING
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            % TOTAL SALES
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500"
            style="min-width: 80px"
          ></div>
        </div>
        <!-- Loop through each parent item (header) -->

        <Draggable
          tag="ul"
          v-model="selected_line_items2"
          class="line-item-drag-group mb-0"
          draggable=".line-item-drag"
          handle=".line-item-drag-icon"
          :force-fallback="true"
          ghost-class="line-item-ghost"
          group="parent-group"
          @start="drag = true"
          @end="drag = false"
        >
          <transition-group
            type="transition"
            :name="!drag ? 'flip-list' : null"
          >
            <li
              v-for="(parent, parentIndex) in selected_line_items2"
              :key="`line-item-drag-${parent.pUuid}`"
              class="line-item-drag px-0 mb-1 header_color"
            >
              <!-- Parent (Header) Row -->
              <div
                class="d-flex my-0 align-items-center pt-1 blue lighten-5 product_header"
              >
                <div
                  class="font-weight-600 text-center freeze-column"
                  style="min-width: 30px"
                >
                  <v-icon
                    class="line-item-drag-icon cursor-move"
                    color="color-custom-blue"
                    >mdi-drag</v-icon
                  >
                </div>
                <div class="text-left px-0" style="width: 97%">
                  <v-text-field
                    v-model="parent.group_name"
                    class="line-item-header-text px-0"
                    placeholder="Group Name"
                    dense
                    filled
                    solo
                    flat
                    color="cyan"
                    v-on:keyup="emit_values()"
                  >
                    <template v-slot:append>
                      <v-checkbox
                        class="custom-box ma-0"
                        hide-details
                        color="cyan"
                        v-model="parent.isHeader"
                        v-on:change="emit_values()"
                      />
                      <label for="" class="ma-0 p-1"> Show in PDF</label>
                    </template>
                  </v-text-field>
                </div>
                <div class="last-child" style="min-width: 40px">
                  <v-btn icon @click="openDeleteConfirmation(parentIndex)">
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </div>

              <!-- Subheader Items -->
              <Draggable
                tag="ul"
                v-model="parent.children"
                class="line-item-drag-group mb-0"
                draggable=".line-item-drag"
                handle=".line-item-drag-icon"
                ghost-class="line-item-ghost"
                group="child-group"
                :force-fallback="true"
                @start="drag = true"
                @end="drag = false"
                :disabled="parent?.children?.length == 1 ? true : false"
              >
                <transition-group
                  type="transition"
                  :name="!drag ? 'flip-list' : null"
                >
                  <li
                    v-for="(child, childIndex) in parent.children"
                    :key="`child-drag-${parent.pUuid}-${child.cUuid}`"
                    class="line-item-drag px-0"
                  >
                    <div
                      class="d-flex my-0 align-items-center pt-1 blue lighten-5"
                    >
                      <div
                        class="font-weight-600 text-center freeze-column"
                        style="min-width: 30px"
                      >
                        <v-icon
                          class="line-item-drag-icon cursor-move"
                          color="color-custom-blue"
                          >mdi-drag</v-icon
                        >
                      </div>
                      <div class="text-left px-0" style="width: 97%">
                        <v-text-field
                          v-model="child.Subheader"
                          class="line-item-header-text px-0"
                          placeholder="Subheader Name"
                          dense
                          filled
                          solo
                          flat
                          color="cyan"
                          v-on:keyup="emit_values()"
                        />
                      </div>
                      <div class="last-child" style="min-width: 40px">
                        <v-btn icon @click="openDeleteConfirmation(childIndex)">
                          <v-icon color="red">mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <!-- Nested Items -->
                    <Draggable
                      tag="ul"
                      v-model="child.header_item"
                      class="line-item-drag-group mb-0"
                      draggable=".line-item-drag"
                      handle=".line-item-drag-icon"
                      ghost-class="line-item-ghost"
                      group="item-group"
                      @start="drag = true"
                      @end="drag = false"
                    >
                      <transition-group
                        type="transition"
                        :name="!drag ? 'flip-list' : null"
                      >
                        <li
                          v-for="(item, itemIndex) in child.header_item"
                          :key="`item-drag-${parent.pUuid}-${child.cUuid}-${item.ccUuid}`"
                          class="line-item-drag px-0"
                        >
                          <div class="d-flex my-0 align-items-center">
                            <div
                              class="font-weight-600 text-center freeze-column"
                              style="min-width: 30px"
                            >
                              <v-icon
                                class="line-item-drag-icon cursor-move"
                                color="color-custom-blue"
                                >mdi-drag</v-icon
                              >
                            </div>
                            <!-- Editable fields for each item -->
                            <div
                              class="text-left px-1 freeze-column"
                              style="min-width: 200px"
                            >
                              <v-combobox
                                dense
                                color="cyan"
                                filled
                                v-model="item.brand"
                                v-on:change="calculate_total()"
                                :items="v2BrandList"
                                placeholder="Select Brand"
                                item-value="text"
                                item-text="text"
                                solo
                                flat
                                hide-details
                                :return-object="false"
                              />
                            </div>
                            <div
                              class="text-left px-1 freeze-column"
                              style="min-width: 200px"
                            >
                              <v-combobox
                                dense
                                color="cyan"
                                filled
                                v-model="item.model"
                                v-on:change="calculate_total()"
                                :items="v2ModelList"
                                placeholder="Select Model"
                                solo
                                flat
                                hide-details
                                :return-object="false"
                              />
                            </div>
                            <div
                              class="text-left px-1 freeze-column"
                              style="min-width: 200px"
                            >
                              <v-combobox
                                dense
                                color="cyan"
                                filled
                                v-model="item.part"
                                :items="v2PartList"
                                placeholder="Select Part"
                                v-on:change="calculate_total()"
                                solo
                                flat
                                hide-details
                                :return-object="false"
                              />
                            </div>
                            <div class="text-left px-1 freeze-column">
                              <v-combobox
                                dense
                                color="cyan"
                                filled
                                v-model="item.product"
                                :items="v2ProductType"
                                placeholder="Select Product"
                                solo
                                flat
                                hide-details
                                v-on:change="calculate_total()"
                                :return-object="false"
                              />
                            </div>
                            <div class="text-left px-1 highlighted" style="">
                              <v-autocomplete
                                dense
                                color="cyan"
                                filled
                                id="costing-currency"
                                :items="v2CurrencyList"
                                v-model.trim="item.cost_currency"
                                placeholder="Select Currency"
                                solo
                                flat
                                item-color="cyan"
                                item-text="text"
                                item-value="value"
                                hide-details
                                v-on:change="calculate_total()"
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-html="'No Currency Found.'"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                            </div>
                            <div class="text-left px-1 highlighted" style="">
                              <v-text-field
                                type="number"
                                v-model="item.cost"
                                class="line-item-header-text px-0"
                                placeholder="Cost"
                                dense
                                filled
                                solo
                                flat
                                color="cyan"
                                v-on:keyup="calculate_total()"
                                v-on:keypress="limitDecimal($event, item.cost)"
                              />
                            </div>
                            <div class="text-left px-1" style="">
                              <v-text-field
                                type="number"
                                v-model="item.flt"
                                class="line-item-header-text px-0"
                                placeholder="FLT"
                                dense
                                filled
                                solo
                                flat
                                color="cyan"
                                v-on:keyup="calculate_total()"
                                v-on:keypress="limitDecimal($event, item.flt)"
                              />
                            </div>
                            <div class="text-left px-1" style="">
                              <v-text-field
                                type="number"
                                v-on:keyup="calculate_total()"
                                v-on:keypress="limitDecimal($event, item.xr)"
                                v-model="item.xr"
                                class="line-item-header-text px-0"
                                placeholder="XR"
                                dense
                                filled
                                solo
                                flat
                                color="cyan"
                              />
                            </div>
                            <div class="text-left px-1" style="">
                              <v-text-field
                                type="number"
                                v-on:keyup="calculate_total()"
                                v-on:keypress="limitDecimal($event, item.gear)"
                                v-model="item.gear"
                                class="line-item-header-text px-0"
                                placeholder="GEAR"
                                dense
                                filled
                                solo
                                flat
                                color="cyan"
                              />
                            </div>
                            <div class="text-left px-1" style="">
                              <v-text-field
                                type="number"
                                v-on:keyup="calculate_total()"
                                v-on:keypress="limitDecimal($event, item.lamp)"
                                v-model="item.lamp"
                                class="line-item-header-text px-0"
                                placeholder="LAMP"
                                dense
                                filled
                                solo
                                flat
                                color="cyan"
                              />
                            </div>
                            <div class="text-left px-1" style="">
                              <v-text-field
                                type="number"
                                v-on:keyup="calculate_total()"
                                v-on:keypress="limitDecimal($event, item.acc)"
                                v-model="item.acc"
                                class="line-item-header-text px-0"
                                placeholder="ACC"
                                dense
                                filled
                                solo
                                flat
                                color="cyan"
                              />
                            </div>
                            <div class="text-left px-1 highlighted" style="">
                              <v-text-field
                                v-on:keyup="calculate_total()"
                                type="number"
                                v-on:keypress="
                                  limitDecimal($event, item.unit_cost)
                                "
                                v-model="item.unit_cost"
                                class="line-item-header-text px-0"
                                placeholder="Unit Cost"
                                dense
                                filled
                                solo
                                flat
                                color="cyan"
                                readonly
                              />
                            </div>
                            <div class="text-left px-1" style="">
                              <v-text-field
                                v-on:keyup="calculate_total()"
                                type="number"
                                v-on:keypress="
                                  limitDecimal($event, item.margin)
                                "
                                v-model="item.margin"
                                class="line-item-header-text px-0"
                                placeholder="Markup"
                                dense
                                filled
                                solo
                                flat
                                color="cyan"
                              />
                            </div>
                            <div class="text-left px-1 highlighted" style="">
                              <v-text-field
                                v-on:keyup="calculate_total()"
                                type="number"
                                v-on:keypress="
                                  limitDecimal($event, item.unit_selling)
                                "
                                v-model="item.unit_selling"
                                class="line-item-header-text px-0"
                                placeholder="Unit Selling"
                                dense
                                filled
                                solo
                                flat
                                color="cyan"
                                readonly
                              />
                            </div>
                            <div class="text-left px-1" style="">
                              <v-text-field
                                type="number"
                                v-on:keyup="calculate_total()"
                                v-on:keypress="isNumber($event)"
                                v-model="item.required_quantity"
                                class="line-item-header-text px-0"
                                placeholder="Required Quantity"
                                dense
                                filled
                                solo
                                flat
                                color="cyan"
                              />
                            </div>
                            <div class="text-left px-1" style="">
                              <v-text-field
                                type="number"
                                v-on:keyup="calculate_total()"
                                v-on:keypress="isNumber($event)"
                                v-model="item.proquantity"
                                class="line-item-header-text px-0"
                                placeholder="Quantity"
                                dense
                                filled
                                solo
                                flat
                                color="cyan"
                              />
                            </div>
                            <div class="text-left px-1" style="">
                              <v-autocomplete
                                dense
                                color="cyan"
                                filled
                                id="uom"
                                :items="uomListType"
                                v-model.trim="item.uom"
                                placeholder="UOM"
                                solo
                                flat
                                item-color="cyan"
                                item-text="display_name"
                                item-value="id"
                                hide-details
                                v-on:change="calculate_total()"
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-html="'No UOM Found.'"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                            </div>
                            <div class="text-left px-1 highlighted" style="">
                              <v-text-field
                                type="number"
                                v-on:keypress="
                                  limitDecimal($event, item.pro_unit_selling)
                                "
                                v-model="item.pro_unit_selling"
                                class="line-item-header-text px-0"
                                placeholder="Unit Selling"
                                dense
                                filled
                                solo
                                flat
                                color="cyan"
                                readonly
                                v-on:keyup="calculate_total()"
                              />
                            </div>
                            <div class="text-left px-1 highlighted" style="">
                              <v-text-field
                                type="number"
                                v-on:keypress="
                                  limitDecimal($event, item.total_cost)
                                "
                                v-model="item.total_cost"
                                class="line-item-header-text px-0"
                                placeholder="Total Cost"
                                dense
                                filled
                                solo
                                flat
                                color="cyan"
                                readonly
                                v-on:keyup="calculate_total()"
                              />
                            </div>
                            <div class="text-left px-1 highlighted" style="">
                              <v-text-field
                                type="number"
                                v-on:keypress="
                                  limitDecimal($event, item.total_selling)
                                "
                                v-model="item.total_selling"
                                class="line-item-header-text px-0"
                                placeholder="Total Selling"
                                dense
                                filled
                                solo
                                flat
                                color="cyan"
                                readonly
                                v-on:keyup="calculate_total()"
                              />
                            </div>
                            <div class="text-left px-1" style="">
                              <v-text-field
                                type="number"
                                v-on:keypress="
                                  limitDecimal($event, item.total_sales)
                                "
                                v-model="item.total_sales"
                                class="line-item-header-text px-0"
                                placeholder="Total Sales"
                                append-icon="mdi-percent-outline"
                                dense
                                filled
                                solo
                                flat
                                color="cyan"
                                readonly
                                v-on:keyup="calculate_total()"
                              />
                            </div>
                            <div class="last-child" style="min-width: 80px">
                              <!-- Button to remove child -->
                              <v-btn
                                icon
                                @click="
                                  removeChild(
                                    parentIndex,
                                    childIndex,
                                    itemIndex
                                  )
                                "
                              >
                                <v-icon color="red">mdi-delete</v-icon>
                              </v-btn>
                              <!-- Button to add a new child -->
                              <v-btn
                                icon
                                @click="
                                  addChildItem(
                                    parentIndex,
                                    childIndex,
                                    itemIndex
                                  )
                                "
                              >
                                <v-icon color="green">mdi-plus</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </li>
                      </transition-group>
                    </Draggable>
                    <div class="d-flex">
                      <div style="width: 2165px"></div>
                      <div style="width: 116px" class="font-weight-600 text-h6">
                        Total
                      </div>
                      <div style="width: 116px">
                        {{ child.p_unit_selling }}
                      </div>
                      <div style="width: 116px">
                        {{ child.p_total_cost }}
                      </div>
                      <div style="width: 116px" class="font-weight-600 text-h6">
                        {{ child.p_total_saling }}
                      </div>
                      <div style="width: 116px">
                        {{ child.p_total_sales }} %
                      </div>
                    </div>
                  </li>
                </transition-group>
              </Draggable>
            </li>
          </transition-group>
        </Draggable>

        <template v-if="!selected_line_items2.length">
          <div class="custom-border-top p-3">
            <p
              class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border d-flex align-center"
            >
              <v-icon class="mr-1">mdi-alert-circle-outline</v-icon>
              Oops... Nothing Found.
            </p>
          </div>
        </template>
      </div>
    </div>

    <!-- header component btn -->
    <div class="d-flex mt-2">
      <div class="d-flex">
        <v-btn-toggle color="cyan" group>
          <!--   <v-btn
            color="cyan"
            class="custom-bold-button"
            value="header"
            @click="addParent"
            style="height: 32px !important"
          >
            Header
          </v-btn> -->
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="other"
            v-on:click="add_component(0)"
            style="height: 32px !important"
          >
            Component
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>

    <!-- total of line item -->
    <div>
      <table width="100%" style="table-layout: fixed">
        <tbody>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600 text-h5"
            >
              Total Selling
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600 text-h5"
            >
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <label
                    v-bind="attrs"
                    v-on="on"
                    class="font-size-16 font-weight-600 m-0"
                    >{{ formatMoney(total_selling) }}</label
                  >
                </template>
                <span>Sum of All Total Selling</span>
              </v-tooltip>
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Total Cost Value
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <label
                    v-bind="attrs"
                    v-on="on"
                    class="font-size-16 font-weight-600 m-0"
                    >{{ formatMoney(total_cost_value) }}</label
                  >
                </template>
                <span>Sum of All Total Cost</span>
              </v-tooltip>
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Total Margin
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <label
                    v-bind="attrs"
                    v-on="on"
                    class="font-size-16 font-weight-600 m-0"
                    >{{ formatNumber(total_margin) }} %</label
                  >
                </template>
                <span>((Total Selling - Total Cost) / Total Selling )*100</span>
              </v-tooltip>
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Percentage Discount
            </td>
            <td width="15%" valign="middle" align="center">
              <div class="d-flex">
                <v-text-field
                  hide-details
                  class="line-item-header-text px-0"
                  placeholder="Percentage Discount"
                  dense
                  filled
                  solo
                  flat
                  v-model="discount_value"
                  v-on:keyup="calculate_total()"
                  v-on:keypress="limitDecimal($event, discount_value)"
                  color="cyan"
                />

                <v-select
                  :items="discount_type_list"
                  v-model="discount_type"
                  hide-details
                  item-text="type"
                  item-value="value"
                  item-color="cyan"
                  class="line-item-header-text discount-type text-h6 px-0"
                  placeholder="Type"
                  dense
                  filled
                  solo
                  flat
                  v-on:change="calculate_total()"
                  color="cyan"
                />
              </div>
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(main_discount) }}
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Lump Sum Discount
            </td>
            <td width="15%" valign="middle" align="center">
              <div class="d-flex">
                <v-text-field
                  hide-details
                  class="line-item-header-text px-0"
                  placeholder="Lump Sum Discount"
                  dense
                  filled
                  solo
                  flat
                  v-model="lump_discount_value"
                  v-on:keyup="calculate_total()"
                  v-on:keypress="limitDecimal($event, lump_discount_value)"
                  color="cyan"
                />

                <v-select
                  :items="discount_type_list"
                  v-model="lump_discount_type"
                  hide-details
                  item-text="type"
                  item-value="value"
                  item-color="cyan"
                  class="line-item-header-text discount-type text-h6 px-0"
                  placeholder="Type"
                  dense
                  filled
                  solo
                  flat
                  v-on:change="calculate_total()"
                  color="cyan"
                />
              </div>
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(lump_sum_discount) }}
            </td>
          </tr>

          <tr v-if="false">
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Discounted Total Selling
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(discounted_total_selling) }}
            </td>
          </tr>
          <tr class="font-size-19">
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600 text-h5"
            >
              Grand Total
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600 text-h5"
            >
              {{ formatMoney(grand_total) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Confirmation Dialog -->
    <v-dialog v-model="showDeleteConfirmation" max-width="600px">
      <DeleteSmallConfirm
        :message="'Are you sure you want to delete this item?'"
        @success="handleDelete"
        @cancel="handleCancel"
      />
    </v-dialog>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin.js";
import {
  SET_LINE_ITEM,
  SET_CALCULATION,
} from "@/core/services/store/line.item.v2.module";
import Draggable from "vuedraggable";
import DeleteSmallConfirm from "@/view/pages/partials/DeleteSmallConfirm";
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import { mapGetters } from "vuex";
import {
  toBtxNumber /* , toBtxFixed */,
} from /* toRoundUp */ "@/core/services/common.service";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  name: "line-item-v1",
  mixins: [CommonMixin],
  data() {
    return {
      showDeleteConfirmation: false,
      drag: false,
      firstParentAdded: null,
      brandList: ["JRLite", "ELDOLED", "MEANWELL"],
      modelList: [
        "COVEFLEX1808",
        "AP-293C-P",
        "AC022-AP",
        "LINEARDRIVE",
        "LPV",
      ],
      partList: [
        "RURE IQ LED STRIPS",
        "ALUM PROFILE",
        "SS CLIPS",
        "LIN210",
        "60-24",
      ],
      productType: ["Brand", "Accessories"],
      currencyListType: ["SGD", "USD"],
      uomListType: ["unit", "kg", "gm", "mm", "nos", "meters"],
      discount_value: null,
      lump_discount_value: null,
      discount_type: 2,
      lump_discount_type: 1,
      discount_type_list: [
        { type: "%", value: 2 },
        { type: "$", value: 1 },
      ],
      total_selling: 0,
      total_cost_value: 0,
      total_margin: 0,
      total_sales: 0,
      main_discount: 0,
      lump_sum_discount: 0,
      discounted_total_selling: 0,
      grand_total: 0,
      show1: false,
      selected_line_items2: [
        {
          pIndex: uuidv4(),
          pUuid: uuidv4(),
          order: 1,
          isHeader: false,
          group_name: "Parent Group Name 1",
          children: [
            {
              cIndex: uuidv4(),
              cUuid: uuidv4(),
              Subheader: "Aircon",
              isSubHeader: true,
              header_item: [
                {
                  ccIndex: uuidv4(),
                  ccUuid: uuidv4(),
                  brand: null,
                  model: null,
                  part: null,
                  product: null,
                  cost_currency: null,
                  cost: 15.8,
                  flt: 1.2,
                  xr: 1.5,
                  gear: null,
                  lamp: null,
                  acc: 5.0,
                  unit_cost: null,
                  margin: 30.0,
                  unit_selling: null,
                  required_quantity: 20,
                  proquantity: 20,
                  uom: null,
                  pro_unit_selling: null,
                  total_cost: null,
                  total_selling: null,
                  total_sales: null,
                },
              ],
              p_unit_selling: 0,
              p_total_sales: 0,
              p_total_saling: 0,
              p_total_cost: 0,
            },
          ],
        },
      ],
      deleteIndex: 0,
    };
  },

  methods: {
    addParent() {
      let len = this.selected_line_items2?.length + 1;
      this.selected_line_items2.push({
        order: len,
        isHeader: false,
        pIndex: uuidv4(),
        pUuid: uuidv4(),
        group_name: "Parent Group Name new",
        children: [],
      });
      this.$nextTick(() => {
        this.emit_values();
      });
    },
    removeParent(parentIndex) {
      this.selected_line_items2.splice(parentIndex, 1);
      this.calculate_total();
      this.emit_values();
    },
    addChildItem(parentIndex, childIndex) {
      this.selected_line_items2[parentIndex].children[
        childIndex
      ].header_item.push({
        ccIndex: uuidv4(),
        ccUuid: uuidv4(),
        model: null,
        part: null,
        product: null,
        cost_currency: null,
        cost: null,
        flt: null,
        xr: null,
        gear: null,
        lamp: null,
        acc: null,
        unit_cost: null,
        margin: null,
        unit_selling: null,
        required_quantity: null,
        proquantity: null,
        uom: null,
        pro_unit_selling: null,
        total_cost: null,
        total_selling: null,
        total_sales: null,
      });
      this.$nextTick(() => {
        this.emit_values();
      });
    },
    removeChild(parentIndex, childIndex, itemIndex) {
      if (
        this.selected_line_items2[parentIndex]?.children[childIndex]
          ?.header_item.length > 1
      ) {
        this.selected_line_items2[parentIndex].children[
          childIndex
        ].header_item.splice(itemIndex, 1);
        this.calculate_total();
        this.emit_values();
      }
    },
    add_component() {
      let len = this.selected_line_items2?.length + 1;
      this.selected_line_items2.push({
        order: len,
        isHeader: false,
        pIndex: uuidv4(),
        pUuid: uuidv4(),
        group_name: null,
        children: [],
      });

      let lastIndex = this.selected_line_items2?.length - 1;
      if (lastIndex < 0) {
        lastIndex = 0;
      }
      this.selected_line_items2[lastIndex]?.children.push({
        Subheader: "Aircon new 1",
        isSubHeader: true,
        cIndex: uuidv4(),
        cUuid: uuidv4(),
        header_item: [
          {
            ccIndex: uuidv4(),
            ccUuid: uuidv4(),
            model: null,
            part: null,
            product: null,
            cost_currency: null,
            cost: null,
            flt: null,
            xr: null,
            gear: null,
            lamp: null,
            acc: null,
            unit_cost: null,
            margin: null,
            unit_selling: null,
            required_quantity: null,
            proquantity: null,
            uom: null,
            pro_unit_selling: null,
            total_cost: null,
            total_selling: null,
            total_sales: null,
          },
        ],
        p_unit_selling: 0,
        p_total_sales: 0,
        p_total_saling: 0,
        p_total_cost: 0,
      });

      this.$nextTick(() => {
        this.emit_values();
      });
    },

    openDeleteConfirmation(index) {
      this.deleteIndex = index;
      this.showDeleteConfirmation = true;
    },
    handleDelete() {
      this.removeParent(this.deleteIndex);
      this.showDeleteConfirmation = false;
    },

    handleCancel() {
      this.showDeleteConfirmation = false;
    },
    syncScrollFromBottom() {
      this.$refs.topScrollbar.scrollLeft =
        this.$refs.scrollContainer.scrollLeft;
    },
    syncScrollFromTop() {
      this.$refs.scrollContainer.scrollLeft =
        this.$refs.topScrollbar.scrollLeft;
    },

    calculate_total() {
      this.total_sales = 0;
      this.total_selling = 0;
      this.total_cost_value = 0;
      this.total_margin = 0;

      for (let i = 0; i < this.selected_line_items2?.length; i++) {
        const lineItem = this.selected_line_items2[i];
        if (!lineItem) continue;

        for (let j = 0; j < lineItem.children?.length; j++) {
          const child = lineItem.children[j];
          if (!child) continue;

          // Reset totals for the current child
          let totalProRatedUnitSell = 0;
          let totalCost = 0;
          let totalSelling = 0;

          for (let k = 0; k < child.header_item?.length; k++) {
            const superChildRow = child.header_item[k];
            if (!superChildRow) continue;

            const cost = toBtxNumber(superChildRow.cost) || 0;
            const flt = toBtxNumber(superChildRow.flt) || 1;
            const xr = toBtxNumber(superChildRow.xr) || 1;
            const gear = toBtxNumber(superChildRow.gear) || 0;
            const lamp = toBtxNumber(superChildRow.lamp) || 0;
            const acc = toBtxNumber(superChildRow.acc) || 0;
            const markup = (toBtxNumber(superChildRow.margin) || 0) / 100;
            const required_quantity =
              toBtxNumber(superChildRow.required_quantity) || 0;
            const proquantity = toBtxNumber(superChildRow.proquantity) || 1;

            // Calculate Unit Cost
            const calculatedValue = cost * flt * xr + (gear + lamp + acc);

            superChildRow.unit_cost = this.accountingToFixed(
              toBtxNumber(calculatedValue)
            );

            const unit_cost = toBtxNumber(superChildRow.unit_cost) || 0;

            // Calculate Unit Selling
            const unitsellValue =
              unit_cost !== 0 ? unit_cost / (1 - markup) : 0;
            superChildRow.unit_selling = this.accountingToFixed(
              toBtxNumber(unitsellValue)
            );

            // Calculate Pro Rated Unit Selling
            const proRatedUnitSell =
              proquantity !== 0
                ? (unitsellValue * required_quantity) / proquantity
                : 0;
            superChildRow.pro_unit_selling = this.accountingToFixed(
              Math.ceil(proRatedUnitSell)
            );

            // Calculate Total Cost
            const total_cost = unit_cost * required_quantity;
            superChildRow.total_cost = this.accountingToFixed(
              toBtxNumber(total_cost)
            );

            // Calculate Total Selling
            const total_selling =
              superChildRow.pro_unit_selling * required_quantity;

            superChildRow.total_selling = this.accountingToFixed(total_selling);

            // Accumulate totals
            totalProRatedUnitSell += Math.ceil(proRatedUnitSell);
            totalCost += total_cost;
            totalSelling += total_selling;
          }

          // Set accumulated values for the current child
          child.p_unit_selling = this.accountingToFixed(totalProRatedUnitSell);
          child.p_total_saling = this.accountingToFixed(totalSelling);
          child.p_total_cost = this.accountingToFixed(totalCost);

          // Accumulate overall totals
          this.total_selling += totalSelling;
          this.total_cost_value += totalCost;
        }
      }

      // Calculate total margin
      this.total_margin =
        this.total_selling && this.total_cost_value
          ? ((this.total_selling - this.total_cost_value) /
              this.total_selling) *
            100
          : 0;

      this.main_discount = 0;
      if (this.discount_type == 1) {
        this.main_discount = toBtxNumber(this.discount_value);
      }
      if (this.discount_type == 2) {
        this.main_discount =
          (toBtxNumber(this.total_selling) * toBtxNumber(this.discount_value)) /
          100;
      }
      this.lump_sum_discount = 0;
      if (this.lump_discount_type == 1) {
        this.lump_sum_discount = toBtxNumber(this.lump_discount_value);
      }
      if (this.lump_discount_type == 2) {
        this.lump_sum_discount =
          (toBtxNumber(this.total_selling) *
            toBtxNumber(this.lump_discount_value)) /
          100;
      }
      this.grand_total =
        this.total_selling - this.main_discount - this.lump_sum_discount;
      this.grand_total = toBtxNumber(this.grand_total);

      let totalSales = 0;
      for (let i = 0; i < this.selected_line_items2?.length; i++) {
        const lineItem = this.selected_line_items2[i];
        if (!lineItem) continue;

        for (let j = 0; j < lineItem.children?.length; j++) {
          const child = lineItem.children[j];
          if (!child) continue;

          for (let k = 0; k < child.header_item?.length; k++) {
            const superChildRow = child.header_item[k];
            if (!superChildRow) continue;

            const total_selling = toBtxNumber(superChildRow.total_selling) || 0;

            const total_sales =
              this.total_selling > 0
                ? (total_selling / this.total_selling) * 100
                : 0;
            superChildRow.total_sales = this.accountingToFixed(total_sales);

            totalSales += total_sales;
          }
          child.p_total_sales = this.accountingToFixed(totalSales);
        }
      }
      this.emit_values();
    },
    emit_values() {
      this.$nextTick(() => {
        this.$store.dispatch(SET_CALCULATION, {
          total_selling: this.formatNumber(this.total_selling),
          total_cost_value: this.formatNumber(this.total_cost_value),
          total_margin: this.formatNumber(this.total_margin),
          discount_value: this.formatNumber(this.discount_value),
          discount_type: this.discount_type,
          main_discount: this.formatNumber(this.main_discount),
          lump_discount_value: this.formatNumber(this.lump_discount_value),
          lump_discount_type: this.lump_discount_type,
          lump_sum_discount: this.lump_sum_discount,
          discounted_total_selling: this.formatNumber(
            this.discounted_total_selling
          ),
          grand_total: this.formatNumber(this.grand_total),
        });
        this.$store.dispatch(SET_LINE_ITEM, this.selected_line_items2);
      });
    },
  },
  mounted() {
    // Synchronize initial scroll position if needed
    this.$refs.topScrollbar.scrollLeft = this.$refs.scrollContainer.scrollLeft;
    this.calculate_total();
  },
  watch: {
    v2EditLineItem() {
      this.selected_line_items2 = this.v2EditLineItem;
      this.calculate_total();
    },
    v2EditCalculation() {
      this.total_selling = this.v2EditCalculation?.total_selling;
      this.total_cost_value = this.v2EditCalculation?.total_cost_value;
      this.total_margin = this.v2EditCalculation?.total_margin;
      this.discount_value = this.v2EditCalculation?.discount_value;
      this.discount_type = this.v2EditCalculation?.discount_type;
      this.main_discount = this.v2EditCalculation?.main_discount;
      this.lump_discount_value = this.v2EditCalculation?.lump_discount_value;
      this.lump_discount_type = this.v2EditCalculation?.lump_discount_type;
      this.lump_sum_discount = this.v2EditCalculation?.lump_sum_discount;
      this.discounted_total_selling =
        this.v2EditCalculation?.discounted_total_selling;
      this.grand_total = this.v2EditCalculation?.grand_total;
      this.calculate_total();
    },
  },
  computed: {
    ...mapGetters([
      "v2UomList",
      "v2BrandList",
      "v2ModelList",
      "v2PartList",
      "v2ProductType",
      "v2CurrencyList",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
      "reCustomerDialog",
      "vSelectedLineItem",
      "vCalculations",
      "v2EditLineItem",
      "v2EditCalculation",
    ]),
  },
  components: {
    Draggable,
    DeleteSmallConfirm,
  },
};
</script>
<style scoped>
.line-item-drag-group {
  position: relative;
}
.custom-grey-border .color-custom-blue:nth-child(1) {
  position: sticky;
  left: 0;
  background: #f5f5f5;
}
.custom-grey-border .color-custom-blue:nth-child(2) {
  position: sticky;
  left: 30px;
  background: #f5f5f5;
}
.custom-grey-border .color-custom-blue:nth-child(3) {
  position: sticky;
  left: 235px;
  background: #f5f5f5;
}
.custom-grey-border .color-custom-blue:nth-child(4) {
  position: sticky;
  left: 440px;
  background: #f5f5f5;
}
.custom-grey-border .color-custom-blue:last-child {
  position: sticky;
  right: 0px;
  background: #f5f5f5;
}

.line-item-drag .product_header .freeze-column:first-child {
  position: sticky;
  left: 0px;
  background: #e0f7fa !important;
  z-index: 9;
}
.line-item-drag .product_header .freeze-column:nth-child(2) {
  position: sticky;
  left: 30px;
  background: #e0f7fa !important;
  z-index: 9;
}
.line-item-drag .freeze-column:nth-child(1) {
  position: sticky;
  left: 0px;
  /* background: #fff !important; */
  z-index: 9;
}

.line-item-drag .freeze-column:nth-child(2) {
  position: sticky;
  left: 30px;
  background: #fff !important;
  z-index: 9;
}
.line-item-drag .freeze-column:nth-child(3) {
  position: sticky;
  left: 230px;
  background: #fff !important;
  z-index: 9;
}
.line-item-drag .freeze-column:nth-child(4) {
  position: sticky;
  left: 430px;
  background: #fff !important;
  z-index: 9;
}
.line-item-drag .highlighted {
  background: antiquewhite;
}

.line-item-drag .product_header .last-child:last-child {
  position: sticky;
  right: 0px;
  z-index: 9;
  background: #e0f7fa;
}
.line-item-drag .last-child:last-child {
  position: sticky;
  right: 0px;
  z-index: 9;
  background: #fff;
}

.line-item-v2 .line-item-drag {
  padding-top: 0px;
  padding-bottom: 0px;
}
.line-item-v2 .line-item-drag .freeze-column {
  padding: 2px 0px;
}
.line-item-v2 li.line-item-drag:nth-child(even) {
  background: no-repeat !important;
}
.line-item-v2 li.line-item-drag.header_color .product_header {
  background: rgb(217 214 214 / 64%) !important;
}
.line-item-v2 li.line-item-drag.header_color .product_header .freeze-column {
  background: rgb(217 214 214 / 64%) !important;
}
.line-item-v2 li.line-item-drag.header_color .product_header .last-child {
  background: rgb(217 214 214 / 64%) !important;
}
/* scroll css */
.custom-grey-border {
  position: relative;
}

.scrollbar-wrapper {
  overflow: hidden;
}

.scrollbar {
  height: 10px; /* Same height as bottom scrollbar */
  overflow-x: scroll;
  background-color: transparent;
}

.scrollbar .scroll-content {
  height: 1px;
  background-color: transparent;
  width: 2697px;
}

.content-wrapper {
  height: auto; /* Adjust height as needed */
}

.scrollbar::-webkit-scrollbar {
  height: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Style scrollbar thumb */
  border-radius: 0px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
</style>
